$breakpoint-xl: 1440px;
$breakpoint-lg: 1280px;
$breakpoint-md: 1024px;
$breakpoint-sm: 768px;
$breakpoint-xs: 576px;

:export {
  mobile: $breakpoint-xs;
  tablet: $breakpoint-sm;
  desktop: $breakpoint-md;
  large: $breakpoint-lg;
  retina: $breakpoint-xl;
}
