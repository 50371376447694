$font-family-headings: "Merriweather", -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;
$font-family-body: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif;

@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Light.ttf");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Merriweather";
  src: url("/fonts/Merriweather-Black.ttf");
  font-weight: 900;
  font-display: swap;
}

// SOURCE SANTS FAMILY

@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-ExtraLight.ttf");
  font-weight: 200;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-display: swap;
  src: url("/fonts/SourceSansPro-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("/fonts/SourceSansPro-Black.ttf");
  font-weight: 900;
  font-display: swap;
}
