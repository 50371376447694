$color-primary: #83bc00;
$color-primary-strong: #638d03;
$color-secondary: #f4f7ef;
$color-tertiary: #397d2d;

$color-black: #0b0f00;
$color-gray800: #4e4e4e;
$color-gray400: #6f6f6f;
$color-gray200: #9a9a9a;
$color-gray100: #dedede;
$color-gray75: #f4f4f4;
$color-gray50: #f2f2f2;
$color-white: #ffffff;
$color-transparent: rgba(0, 0, 0, 0);

// COLOR FAMILIES
$color-bioestimulantes: #00b2a9;
$color-acondicionadores: #f0c02c;
$color-lignovert: #9ea1a3;
$color-correctores: #f27c2b;
$color-aditivos: #5c90bd;
$color-quelatos: #abcd38;
$color-npks: #e81d55;
$color-protector: #e1a4aa;

$color-danger: #b22121;

:export {
  primary: $color-primary;
  primary-strong: $color-primary-strong;
  secondary: $color-secondary;
  tertiary: $color-tertiary;
  black: $color-black;
  gray800: $color-gray800;
  gray400: $color-gray400;
  gray200: $color-gray200;
  gray100: $color-gray100;
  gray75: $color-gray75;
  gray50: $color-gray50;
  white: $color-white;
  transparent: $color-transparent;
  bioestimulantes: $color-bioestimulantes;
  acondicionadores: $color-acondicionadores;
  lignovert: $color-lignovert;
  correctores: $color-correctores;
  aditivos: $color-aditivos;
  quelatos: $color-quelatos;
  npks: $color-npks;
  protector: $color-protector;
  danger: $color-danger;
}
