@import "./tokens.scss";

$colors-map: (
  primary: $color-primary,
  primary-strong: $color-primary-strong,
  secondary: $color-secondary,
  tertiary: $color-tertiary,
  black: $color-black,
  gray800: $color-gray800,
  gray400: $color-gray400,
  gray200: $color-gray200,
  gray100: $color-gray100,
  gray75: $color-gray75,
  gray50: $color-gray50,
  white: $color-white,
  transparent: $color-transparent,
  bioestimulantes: $color-bioestimulantes,
  acondicionadores: $color-acondicionadores,
  lignovert: $color-lignovert,
  correctores: $color-correctores,
  aditivos: $color-aditivos,
  quelatos: $color-quelatos,
  npks: $color-npks,
  protector: $color-protector,
  danger: $color-danger,
);

@each $color, $value in $colors-map {
  .color-#{$color} {
    color: $value;
  }
  .bg-color-#{$color} {
    background-color: $value;
  }
}
