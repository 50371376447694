@import "./fonts.scss";
@import "./helpers.scss";
@import "./fonts.scss";
@import "./typographies.scss";
@import "./color-helpers.scss";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.4rem;
  width: 100vw;
  overflow-x: hidden;
  @include helper-hide-scrollbar();
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font-family-body;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  font-family: $font-family-body;
  box-sizing: border-box;
}

button {
  cursor: pointer;
  color: $color-black;
}
