@import "./fonts.scss";

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-family-headings;
}

input,
select,
textarea {
  border-radius: 2px;
  background-clip: padding-box;
  &::placeholder {
    font-family: $font-family-body;
  }
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &::-ms-input-placeholder,
  &::-moz-placeholder {
    font-family: $font-family-body;
  }
}
